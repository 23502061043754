<template lang='pug'>
  extends ../templates/PageBase.pug
  block content
    .container-fluid.container-fixed-lg
      .row.no-margin
        .col-md-6.no-padding.b-r.b-grey
          .card.card-default
            .card-header
              .card-title
                | JSON - &nbsp;
                small Paste Data here to edit
            .card-body
              code-editor(mode='json' name='json' ref='json')
        .col-md-6.no-padding
          .card.card-default
            .card-header
              .card-title
                | YAML - &nbsp;
                small Paste Data here to edit
            .card-body
              code-editor(mode='yaml' name='yaml' ref='yaml' mime-type='application/x-yaml' file-ext='yaml')

</template>

<script>
  import CodeEditor from '../components/CodeEditor'
  import YAML from 'js-yaml'

  export default {
    name: 'JSONYaml',
    components: {CodeEditor},
    mounted() {
      const self = this

      this.$on('editorContentChanged', function (event) {
        if (self.editorChanged) return
        self.blockEditing(true)

        const cursor = event.editor.getCursorPosition()

        try {
          window.console.log('name,', event)
          if (event.name === 'json') {
            const jsonData = JSON.parse(self.$refs.json.content)
            const yamlIndent = self.$refs.yaml.tabSettings.spaces
            const ymlData = YAML.safeDump(jsonData, {indent: yamlIndent})

            self.$refs.yaml.setContent(ymlData)
            self.$refs.yaml.editor.gotoLine(cursor.row + 1, cursor.column)

          } else if (event.name === 'yaml') {
            const yamlData = self.$refs.yaml.content
            const jsonIndent = self.$refs.json.tabSettings.spaces
            const jsonData = JSON.stringify(YAML.safeLoad(yamlData), null, jsonIndent)

            self.$refs.json.setContent(jsonData)
            self.$refs.json.editor.gotoLine(cursor.row + 1, cursor.column)
          }

        } catch (err) {
          window.console.log('Exectptio', err)
        }

        self.blockEditing(false)
      })

    },
    data () {
      return {
        editorChanged: false
      }
    },
    methods: {
      blockEditing(block) {
        this.editorChanged = block
        this.$refs.json.isEditing = block
        this.$refs.yaml.isEditing = block
      }
    }

  }
</script>

